import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import globalModel from "@/models/global.model";
import planModel from "@/models/plan.model";
import { useReactive, useRequest } from "ahooks";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CountDown,
  Form,
  Image,
  ImagePreview,
  Popup,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";
import NumberStepper from "@/components/NumberStepper";

export default () => {
  const state = useReactive({
    scrollTop: 0,
    visible: false,
  });
  const { user } = useSnapshot(globalModel.state);
  const nav = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const { data, refreshAsync } = useRequest(
    () => {
      return planModel.detail(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
      onSuccess: (data) => {
        form?.setFieldValue("num", data?.perLimit ?? 1);
      },
    }
  );
  const onFinish = async (data: any) => {
    state.visible = false;
    const update = Toast({
      type: "loading",
      message: "正在提交...",
      forbidClick: true,
      duration: 0,
    });
    const res = await planModel.order({
      id: params.id,
      ...data,
    });
    if (res?.code === 200) {
      await refreshAsync();
      update.config({
        type: "success",
        message: "下单成功",
        forbidClick: true,
      });
      if (!!res.data?.no) {
        nav(`/order/detail/${res.data?.no}`);
      }
    }
    setTimeout(() => {
      update.clear();
    }, 2000);
  };
  const startDiff = useMemo(() => {
    if (!data?.startTime) return 0;
    return dayjs(data.startTime).diff(dayjs());
  }, [data?.startTime]);
  return (
    <div className={styles.page}>
      <NavBar
        theme="none"
        placeholder={false}
        title={state.scrollTop > 100 ? data?.title : ""}
        onScroll={(e) => (state.scrollTop = e)}
      />
      {!data ? (
        <Loader />
      ) : (
        <>
          <Image
            className={styles.image}
            src={data.image}
            fit="cover"
            onClick={() =>
              ImagePreview.open({
                showIndex: false,
                lazyload: true,
                images: [data.image],
              })
            }
          />

          <div className={styles.asset}>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.tags}>
              <div className={styles.limit}>
                <span className={styles.label}>限量</span>
                <span className={styles.num}>{data.limit}份</span>
              </div>
              <div className={styles.limit}>
                <span className={styles.label}>流通</span>
                <span className={styles.num}>{data.asset?.circulation}份</span>
              </div>
            </div>
          </div>

          <div className={styles.publisher}>
            <div className={styles.left}>
              <Image
                className={styles.logo}
                src={data.asset?.publisher?.logo}
              />
              <div className={styles.info}>
                <div className={styles.label}>发行方</div>
                <div className={styles.name}>{data.asset?.publisher?.name}</div>
              </div>
            </div>
            <div className={styles.right}>
              <Image className={styles.logo} src={data.asset?.creator?.logo} />
              <div className={styles.info}>
                <div className={styles.label}>创作者</div>
                <div className={styles.name}>{data.asset?.creator?.name}</div>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.item}>
              <div className={styles.label}>所属专辑:</div>
              <div className={styles.value}>{data.asset?.album?.title}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>创建时间:</div>
              <div className={styles.value}>
                {dayjs(data.asset?.createdAt).format("YYYY-MM-DD")}
              </div>
            </div>
          </div>

          <div className={styles.section}>资产详情</div>
          <div className={styles.content}>
            <div
              className={styles.detail}
              dangerouslySetInnerHTML={{ __html: data.asset?.detail }}
            />
          </div>
          <div className={styles.section}>特别提示</div>
          <div className={styles.content}>
            <div className={styles.item}>
              数字资产为虚拟数字商品，而非实物，仅限18周岁(不含)以上65周岁(含)以下的中国大陆用户购买。数字资产的版权由发行方或原创作者拥有，除另行取得版权拥有者书面同意外，用户不得将数字资产用于任何商业用途。本商品一经售出，不支持退换。
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.price}>
              <span>￥</span>
              {data.price}
            </div>
            <Button
              className={styles.submit}
              type="primary"
              block
              disabled={!!data.isSoldOut || startDiff > 0}
              onClick={() => {
                if (!user?.isReal) {
                  Toast("请先完成实名认证");
                  return;
                }
                state.visible = true;
              }}
            >
              {data.isSoldOut ? (
                "已售罄"
              ) : startDiff > 0 ? (
                <>
                  即将开售
                  <CountDown
                    time={startDiff}
                    onFinish={async () => {
                      await refreshAsync();
                    }}
                  />
                </>
              ) : (
                "立即购买"
              )}
            </Button>
          </div>
          <Popup
            position="bottom"
            round
            title="选择数量"
            closeable
            closeOnClickOverlay
            visible={state.visible}
            onClose={() => (state.visible = false)}
          >
            <div className={styles.popup}>
              <Form
                className={styles.form}
                form={form}
                onFinish={onFinish}
                footer={
                  <Button
                    className={styles.submit}
                    type="primary"
                    block
                    onClick={async () => {
                      if (!user?.isReal) {
                        Toast("请先完成实名认证");
                        return;
                      }
                      try {
                        await form.validateFields();
                        form.submit();
                      } catch (e: any) {
                        if (e && e.errorFields) {
                          Toast({
                            message: e.errorFields[0]?.errors[0],
                            position: "top",
                          });
                        }
                      }
                    }}
                  >
                    立即购买
                  </Button>
                }
              >
                <Form.Item
                  name="num"
                  noStyle
                  rules={[
                    {
                      validator: async (_, value) => {
                        try {
                          if (!value) {
                            throw new Error("请选择购买数量");
                          }
                          if (value < 1) {
                            throw new Error("每次购买数量不能小于1");
                          }
                          if (value > data.perLimit) {
                            throw new Error(
                              `每次购买数量不能大于${data.perLimit}`
                            );
                          }
                        } catch (err: any) {
                          return Promise.reject(err);
                        }
                      },
                    },
                  ]}
                >
                  <NumberStepper min={1} />
                </Form.Item>
              </Form>
            </div>
          </Popup>
        </>
      )}
    </div>
  );
};
